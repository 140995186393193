import './App.css';
import Site from "./components/Site";

function App() {
    return (
        <div key="dictionary" className="grid place-items-center h-screen w-full bg-gray-500 overflow-auto ">
            <Site/>
        </div>
    );
}

export default App;
