import React, {Component} from "react";
import configData from "../../data.json";

class Site extends Component {

    componentDidMount() {
        let dialger = this.buildDictionary(false)
        let gerdial = this.buildDictionary(true)
        this.setState({germanToDialect: true, gerdial: gerdial, dialger: dialger}, this.switch)
    }

    componentDidUpdate() {
    }

    getData() {
        let dictionary = new Map()
        for (let key in configData.words) {
            let word = configData.words[key]
            let german = word["german"].split("|")
            let dialect = word["dialect"].split("|")
            for (const i in german) {
                for (const j in dialect) {
                    dictionary.set(german[i], dialect[j])
                }
            }
        }
        return dictionary
    }

    buildDictionary(direction) {
        let dictionary = this.getData()
        let sorted = new Map()
        let counter = 0
        const list = []

        dictionary.forEach((v, k) => {
            let from = direction ? k : v
            let to = direction ? v : k
            let beginning = from.trim().charAt(0).toLowerCase()
            if (sorted.has(beginning)) {
                let l = sorted.get(beginning)
                l.push({from: from, to: to})
                counter++
                sorted.set(beginning, l.sort(function (a, b) {
                    return a.from.toString().toLowerCase().localeCompare(b.to.toString().toLowerCase())
                }))
            } else {
                let l = []
                if (beginning.match(/[a-z]/i)) {
                    l.push({from: beginning.toUpperCase(), to: ""})
                }
                l.push({from: from, to: to})
                counter++
                sorted.set(beginning, l)
            }
        })

        let s = [];
        const assocHelper = new Map();
        sorted.forEach((v, k) => {
            assocHelper.set(k.toString().toLowerCase().charCodeAt(0), k.toString().toUpperCase())
            v.sort(function (a, b) {
                return a.from.localeCompare(b.from)
            });
            s[k.toString().toLowerCase().charCodeAt(0)] = v
        })

        s = s.sort(function (a, b) {
            return (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0)
        });
        s.forEach((v, k) => {
            v.forEach((entry) => {
                list.push(<tr className="table-row" key={entry.from + "-" + entry.to}>
                    <td className="border border-gray-600 dark:border-gray-600 p-4 text-gray-500 dark:text-gray-300">{entry.from}</td>
                    <td className="border border-gray-600 dark:border-gray-600 p-4 text-gray-500 dark:text-gray-300">{entry.to}</td>
                </tr>)
            })
        })
        this.setState({list: list, counter: counter})
        return list
    }

    switch() {
        const directionFunc = () => {
            if (this.state !== null) {
                return this.state.germanToDialect
            } else {
                return false
            }
        }
        const direction = directionFunc()
        let gerdial = this.state.gerdial
        let dialger = this.state.dialger
        this.setState({germanToDialect: !direction, list: directionFunc() ? gerdial : dialger})
    }

    render() {
        if (this.state === null || this.state.list === null) {
            return <div>Translating...</div>
        } else {
            var list = this.state.list
            var direction = this.state.germanToDialect
            var counter = this.state.counter
            var texts = ["Deutsch", "Dialekt", "Einträge"]
            if (direction) {
                texts = ["Dialekt", "Deitsch", "Inträge"]
            }
            return <div>
                <div className="grid place-items-center">
                    <button className="mt-2 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded content-center"
                            onClick={() => {
                                this.switch()
                            }}>
                        {texts[0]} 🔄 {texts[1]}
                    </button>
                </div>
                <table key="table"
                       className="m-2 table-auto border-collapse border border-gray-400 dark:border-gray-500 bg-white dark:bg-gray-800 text-sm shadow-sm">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                        <th className="w-1/2 border border-gray-600 dark:border-gray-600 font-semibold p-4 text-gray-900 dark:text-gray-200 text-center">
                            {texts[0]}
                        </th>
                        <th className="w-1/2 border border-gray-600 dark:border-gray-600 font-semibold p-4 text-gray-900 dark:text-gray-200 text-center">
                            {texts[1]}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {list}
                    </tbody>
                </table>
                <p className="ml-4 mb-2 text-gray-200 text-sm">{counter} {texts[2]}</p>
            </div>
        }
    }
}

export default Site;
